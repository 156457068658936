<template>
  <div>
    <div class="title">认识亚盘</div>
    <el-divider></el-divider>
    <div class="simple">
      近来赛事较多，五大联赛和欧洲国家联赛已经激发了彩民的热情，很快欧冠也要开赛，可玩的比赛越来越多。不过，新彩民虽然买彩热情上来了，但有时看专家分析时，对于什么盘口让球，升水降水什么的还是不甚了解。而了解亚盘有助于我们精准预测比赛。
    </div>
    <div class="body">
      <div class="panel">
        <div class="panel-title">
          什么是亚盘？
        </div>
        <div class="panel-content">
          亚洲盘口简称亚盘，它的存在形态是通过让球盘口使两个相差悬殊的球队间能够在一个相对公平的平台上进行较量，从而拉平双方客观存在的实力差距。这种盘口也可称为独赢盘。由于这种玩法起源于亚洲，因此又被称为亚洲盘口。
        </div>
      </div>

      <div class="panel">
        <div class="panel-title">
          了解亚盘，要先掌握几个基础概念：
        </div>
        <div class="panel-content">
          让球盘：让球盘又叫独赢盘，即在指定的比赛中投注可能生出的球队。例如昨夜的德国vs法国，盘口为平手盘，玩家投注德国，则主队德国赢下至少一个球玩家就可以赢钱。如果法国获胜则投注德国失败，玩家输掉所有本金。如果打平则球队没有输赢，玩家没有盈亏，退还本金不赢不输。
        </div>
      </div>

      <div class="row">
        <img src="@/assets/renshiyapan.png" alt="" />
        <div>
          <div class="panel">
            <div class="panel-title">
              贴水：
            </div>
            <div class="panel-content">
              贴水也称为水位，即投注比赛双方的获胜赔率。以澳门盘举例，比赛中两边球队的获胜贴水总和一般等于1.86，即某一方1.00水位时另一方是0.86水。平手盘表示庄家认为两队处于同一水平，水位越低越被看好。
            </div>
          </div>

          <div class="panel">
            <div class="panel-title">
              上下盘口：
            </div>
            <div class="panel-content">
              让球一方称为上盘，被让球一方则是下盘。例如意大利vs波兰这场，让球的主队意大利是上盘，被让球的波兰是下盘。
            </div>
          </div>

          <div class="panel">
            <div class="panel-title">
              计算盘口输赢：
            </div>
            <div class="panel-content">
              所谓盘口输赢计算，就是让球规则。常见的让球输赢规则如下
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.title {
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #16a085;
  &::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 15px;
    background: #16a085;
    margin-right: 5px;
  }
}
.el-divider {
  margin-top: 5px;
  margin-bottom: 15px;
}
.simple {
  background: #f6f7f8;
  border-radius: 6px;
  padding: 15px;

  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
  color: #333333;
  margin-bottom: 20px;
}
.body {
  margin-bottom: 15px;
}
.panel {
  margin-bottom: 20px;
  font-size: #333;
  line-height: 26px;
  .panel-title {
    font-weight: bold;
  }
}
.row {
  margin-top: 20px;
  display: flex;
  img {
    margin-right: 35px;
  }
}
</style>
